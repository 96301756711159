import React from "react"
import { Box, CssBaseline, Paper, Grid } from "@material-ui/core"
import { useLocation } from "@reach/router"
import Logo from "../../Logo"
import { useStyles } from "./style"
import { Link } from "gatsby"
import ParentLayout from "../../ParentLayout"

const Auth = props => {
  const { children } = props

  const classes = useStyles()
  const location = useLocation()
  const route = location.pathname.split("/")[2]

  return (
    <ParentLayout>
      <Box id="auth" position="relative">
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={6} md={6} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            square
            style={{
              overflowY: "scroll",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              className={
                route === "register" ? classes.register : classes.paper
              }
            >
              <Box className={classes.logo}>
                <Link to="/">
                  <Logo logoDark />
                </Link>
              </Box>
              {children}
            </div>
          </Grid>
        </Grid>
        {/* <Alert {...props} /> */}
        {/* <Box className={classes.whatsapp}>
        <a
          href="https://web.whatsapp.com/send?phone=2348143906442"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            startIcon={<FaWhatsapp />}
            radius="2rem"
            size="small"
            color="primary"
          >
            Chat with Sales Team
          </Button>
        </a>
      </Box> */}
      </Box>
    </ParentLayout>
  )
}

export default Auth
