import { makeStyles } from "@material-ui/core/styles"
import heroHeader from "../../../images/signup.jpg"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `linear-gradient(0deg, rgba(50, 100, 200, 0.3), rgba(50, 100, 200, 0.3)), url(${heroHeader});`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logo: {
    width: "7.5rem",
    marginBottom: "2rem",
    height: "auto"
  },
  paper: {
    margin: theme.spacing(7, 17),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(8, 6),
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(8),
    },
  },
  form: {
    width: "100%",
  },
  formControl: {
    minWidth: `100%`,
    margin: theme.spacing(0.5, 0),
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
  },
  subtitle: {
    fontSize: "18px",
    color: "#9DA9B3",
    marginBottom: "1rem",
  },
  successIcon: {
    height: "7rem",
    margin: theme.spacing(2, 0),
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  actionText: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  text: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  submit: {
    margin: theme.spacing(2, 0, 0, 0),
    minWidth: `100%`,
  },
  emailResend: {
    // margin: theme.spacing(0.5, 0, 0, 0),
    position: 'relative',
    bottom: '1rem',
    minWidth: `100%`,
  }, 
  register: {
      margin: theme.spacing(3, 17, 10),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(8, 6),
      },
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(8),
      },
    
  },
  container: {
    display: "none"
  },
  whatsapp: {
    zIndex: 4,
    position: 'fixed',
    bottom: '4%',
    right: '3%',
}
}))

export { useStyles }
